import React from 'react';

import './footer.scss';

import { Link } from 'react-router-dom';

import bg from '../../assets/footer-bg.jpg';
import logo from '../../assets/tmovie.png';

const Footer = () => {
    return (
        <div className="footer" style={{backgroundImage: `url(${bg})`}}>
            <div className="footer__content container">
                <div className="footer__content__logo">
                    <div className="logo">
                        <img src={logo} alt="" />
                        <Link to="/">Search Movies</Link>
                    </div>
                </div>
                <div className="footer__content__menus">
                    <div className="footer__content__menu">
                        <Link to="/">Home</Link>
                        {/* <Link to="/">Contact us</Link>
                        <Link to="/">Term of services</Link>
                        <Link to="/">About us</Link> */}
                    </div>
                    <div className="footer__content__menu">
                        <Link to="/movies">Movies</Link>
                        {/* <Link to="/">Live</Link>
                        <Link to="/">FAQ</Link>
                        <Link to="/">Premium</Link>
                        <Link to="/">Pravacy policy</Link> */}
                    </div>
                    <div className="footer__content__menu">
                        <Link to="/tv">TV Series</Link>
                        {/* <Link to="/">You must watch</Link>
                        <Link to="/">Recent release</Link>
                        <Link to="/">Top IMDB</Link> */}
                    </div>
                </div>
                
            </div>
            <div className='copyRight'>
                <div>© 2022 abintjose.com | All rights reserved</div>
                <div>Designed by <Link to="https://abintjose.com">Abin T Jose</Link></div>
            </div>
        </div>
    );
}

export default Footer;
